<template>
    <div class="flex mt-auto ml-5">
        <div class="relative px-2" @click="mainAction(item)" v-show="conditionHideWhen(item.HideWhen)" v-for="(item, n) in data" :key="n" :class="[item.Url ? 'cursor-pointer' : '', item.Class]">
            <b-icon :icon="item.Icon" size="is-small" class="mr-2" v-if="item.Icon"></b-icon>
            <span class="font-bold text-lg">{{ item.Name }}</span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Array
        }
    }
}
</script>
